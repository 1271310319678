import { useState } from 'react';
import LoginForm, { UserInterface } from './LoginForm';

function App() {
  const [user, setLoggedInUser] = useState<UserInterface>();
  const [loggedIn, setLoggedIn] = useState(false);

  const userLoggedIn = (user: UserInterface) => {
    setLoggedInUser(user);
    setLoggedIn(true);
  };

  return (
    <div className="App">
      {!loggedIn && (<>
        <LoginForm onLogin={userLoggedIn} />
      </>)}
      {loggedIn && (<>
        <div>{user?.name} loggged in</div>
      </>)}
    </div>
  );
}

export default App;
