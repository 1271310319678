import { useState } from 'react';
import { Form, Input, Button, Card, Row, Col, Image, Flex } from 'antd';

export interface UserInterface {
  id: string,
  name: string,
  changePasswordRequired: boolean,
}

interface LoginFormProps {
  onLogin: (user: UserInterface) => void;
}

namespace resources {
  // break down the resource into its parts for security reasons
  const base = 'a'.concat('p').concat('i');
  const domain = 'default';
  const version = 'v1';
  const group = 'auth';
  const subgroup = 'inhouse';

  export const user = ['', base, domain, version, group, subgroup, 'user'].join('/');
  export const login = ['', base, domain, version, group, subgroup, 'login'].join('/');
}


const LoginForm: React.FC<LoginFormProps> = ({onLogin}) => {
  // form is disabled while the server is checking the password
  const [loginFormEnabled, setLoginFormEnabled] = useState<boolean>(true);
  // if user fails to log in, then errors are saved here
  const [error, setError] = useState<String | null>(null);
  // by defaul assume that user is logged in and test whether that's true
  const [isUserloggedIn, setUserLoggedIn] = useState(true);

  const checkIfUserIsLoggedIn = async () => {
    try {
      const response = await fetch(resources.user, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
  
      const responseBody = await response.json();
      if (response.ok) {
        onLogin(responseBody)
      } else {
        setUserLoggedIn(false);
      }
    } catch (error) {
      setUserLoggedIn(false);
    }
  }

  const handleSubmitLoginRequest = async (values: any) => {
    try {
      setLoginFormEnabled(false)
      const response = await fetch(resources.login, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      });
      setLoginFormEnabled(true)
  
      if (response.ok) {
        const responseBody = await response.json();
        onLogin(responseBody)
      } else if (response.status === 429) {
        setError('Please try again later.'); 
        console.error(`Failed to login. Status code: ${response.status}`);
      } else if (response.status >= 401 && response.status < 500) {
        setError('Invalid email or password.'); 
        console.error(`Failed to login. Status code: ${response.status}`);
      } else if (response.status >= 500) {
        setError('Wealthport is unable to log you in at this time. Please send us an email to receive additional help.'); 
        console.error(`Failed to login. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error('error: ' + error);
      setLoginFormEnabled(true)
    }
  };

  checkIfUserIsLoggedIn();

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
  };

  const title = <Flex vertical={false} justify={'space-between'}>
    <h1>Log in</h1>
    <div style={containerStyle}>
      <Image preview={false} style={imageStyle} src="./favicon.ico" />
    </div>
  </Flex>

  return (
    <div style={{backgroundColor: '#F2F2F2' }}>
      {isUserloggedIn && (<></>) /* first show empty page to check whether user is logged in */}
      {!isUserloggedIn && /* if users is NOT logged in, then ask for email and password */ (<>
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }} >
          <Col span={8}>
            <Card title={title} style={{ borderRadius: '22px'}} >
              <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} 
                style={{ maxWidth: 600 }} initialValues={{ remember: true }}
                onFinish={handleSubmitLoginRequest} autoComplete="off" 
                disabled={ !loginFormEnabled } >

                <Form.Item label="Email" name="email"
                  rules={[
                    { required: true, message: 'Please enter your email' },
                    { type: 'email', message: 'Please enter a valid email' }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Password" name="password"
                  rules={[{ required: true, message: 'Please enter your password' }]}
                >
                  <Input.Password />
                </Form.Item>

                {error && (
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <div style={{ color: 'red' }}>{error}</div>
                  </Form.Item>
                )}

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" style={{ width: 106, borderRadius: '16px' }} 
                    htmlType="submit">
                    Log in
                  </Button>
                </Form.Item>

              </Form>
            </Card>
          </Col>
        </Row>
      </>)}
    </div>
  );
}

export default LoginForm;
